<template>
  <div id="editor"></div>
</template>
<script>
import E from "wangeditor";

let editor = null
export default {
  props: {
    content: {
      type: String,
      default: "",
    },
    height: {
      type: Number,
      default: 400
    }
  },
  mounted() {
    editor = new E("#editor");
    editor.config.height = this.height;
    editor.config.zIndex = 999;
    editor.config.focus = false;
    editor.config.uploadImgServer = "/api/file/upload";
    editor.config.uploadImgMaxSize = 30 * 1024 * 1024 // 2M
    editor.config.uploadImgMaxLength = 1; // 一次最多上传 1 个图片
    editor.config.uploadFileName = "file";
    editor.config.uploadImgHooks = {
      customInsert: function (insertImgFn, result) {
        if (result.code == 200) {
          insertImgFn(result.data);
        }
      },
    };
    editor.config.uploadVideoServer = "/api/file/upload";
    editor.config.uploadVideoMaxSize = 1 * 1024 * 1024 * 1024; // 1024m
    editor.config.uploadVideoAccept = ["mp4"];
    editor.config.uploadVideoName = "file";
    editor.config.uploadVideoHooks = {
      customInsert: function (insertVideoFn, result) {
        if (result.code == 200) {
          insertVideoFn(result.data);
        }
      },
    };

    editor.config.colors = [
      "#FF0000",
      "#000000",
      "#333333",
      "#0000FF",
      "#00FF00",
      "#00FFFF",
      "#FFFF00",
      "#f9963b",
      "#c24f4a",
      "#8baa4a",
      "#7b5ba1",
      "#FF00FF",
      "#70DB93",
      "#D9D919",
      "#D98719",
    ];

    editor.config.menus = [
      "head",
      "bold",
      "fontSize",
      "fontName",
      "italic",
      "underline",
      "strikeThrough",
      "indent",
      "lineHeight",
      "foreColor",
      "backColor",
      "link",
      "list",
      "todo",
      "justify",
      "quote",
      "image",
      "video",
      "table",
      "splitLine",
      "undo",
      "redo",
    ];
    // 创建富文本编辑器
    editor.create();
  },

  methods: {
    getContent() {
      return editor.txt.html();
    },
    setContent(content) {
      editor.txt.html(content);
    },
    clearContent() {
      editor.txt.clear()
    }
  },
};
</script>
<style>
table tr {
  height: 30px;
}

table th {
  height: 30px;
}


</style>
